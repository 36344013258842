import UserRoleEnum from "~/enums/userRoleEnum";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    currentUser: {} as Partial<User> | null,
  }),
  actions: {
    async fetchCurrentUser(userId: string) {
      const { data: userData } = await useFetch(`/api/users/${userId}`);
      this.currentUser = userData.value as User;
    },
    clearCurrentUser() {
      this.currentUser = null;
    },
  },
  getters: {
    isAdmin: (state) => state.currentUser?.role === UserRoleEnum.ADMIN,
    isAdvisor: (state) => state.currentUser?.role === UserRoleEnum.ADVISOR,
    isCpa: (state) => state.currentUser?.role === UserRoleEnum.CPA,
  },
  persist: {
    storage: persistedState.localStorage,
    paths: ["currentUser"],
  },
});
